@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

tr:first-child td:first-child {
  border-top-left-radius: 15px;
}
tr:first-child td:last-child {
  border-top-right-radius: 15px;
}
tr:last-child td:first-child {
  border-bottom-left-radius: 15px;
}
tr:last-child td:last-child {
  border-bottom-right-radius: 15px;
}
